import * as Sentry from '@sentry/sveltekit';
import { httpClientIntegration } from '@sentry/integrations';
import { dev } from '$app/environment';
import { stageName } from '$lib/variables';

Sentry.init({
  dsn: 'https://3805de3c68b390129752f9d18dd07de7@o1010553.ingest.us.sentry.io/4506557245292544',

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.1,

  integrations: [Sentry.browserTracingIntegration(), httpClientIntegration() as any],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/ris-api\.reshapebiotech\.com/,
    /^https:\/\/ris-staging-api\.reshapebiotech\.com/,
  ],
  environment: stageName,
  enabled: !dev,
});

export const handleError = Sentry.handleErrorWithSentry();
